import React, { useState } from "react";
import axios from "../api/myAxios";

const DataTextUpload = ({ workspaceId }) => {
    const [text, setText] = useState("");
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleTextChange = (e) => {
        setText(e.target.value);
        setError('');
        setSuccess(false);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!text.trim()) {
            setError('Please enter some text to upload.');
            return;
        }

        try {
            setUploading(true);
            setError('');

            const response = await axios.post('/api/data/text', {
                text,
                workspaceId
            });

            console.log('Text uploaded successfully:', response.data);
            setSuccess(true);
            setText('');
        } catch (error) {
            console.error('Error uploading text:', error);
            setError('Error uploading text. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="data-upload-container">
            <h4>Upload Text</h4>
            <form onSubmit={handleUpload}>
                <div className="form-group">
                    <textarea
                        className="form-control"
                        value={text}
                        onChange={handleTextChange}
                        placeholder="Enter your text here..."
                        rows="4"
                    />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">Text uploaded successfully!</div>}
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={uploading || !text.trim()}
                >
                    {uploading ? 'Uploading...' : 'Upload Text'}
                </button>
            </form>
        </div>
    );
};

export default DataTextUpload;
