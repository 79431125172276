import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    Button,
    Stack,
    Avatar,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(6),
    }
}));

const HeroSection = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isInitialView'
})(({ theme, isInitialView }) => ({
    textAlign: 'center',
    transition: 'all 0.5s ease-in-out',
    marginBottom: isInitialView ? theme.spacing(4) : theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        marginBottom: isInitialView ? theme.spacing(2) : theme.spacing(1),
    }
}));

const MainTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isInitialView'
})(({ theme, isInitialView }) => ({
    transition: 'all 0.5s ease-in-out',
    fontSize: isInitialView ? '4.5rem' : '2.5rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: isInitialView ? '2.5rem' : '1.8rem',
    },
    fontWeight: 800,
    background: theme.palette.mode === 'dark'
        ? 'linear-gradient(45deg, #90caf9 30%, #64b5f6 90%)'
        : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textShadow: theme.palette.mode === 'dark'
        ? '2px 2px 4px rgba(255,255,255,0.1)'
        : '2px 2px 4px rgba(0,0,0,0.1)',
    fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    letterSpacing: '-0.02em',
    textAlign: 'center',
    padding: '0 16px',
}));

const SubTitle = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isInitialView'
})(({ theme, isInitialView }) => ({
    transition: 'all 0.5s ease-in-out',
    opacity: isInitialView ? 1 : 0,
    maxHeight: isInitialView ? '100px' : '0',
    marginTop: isInitialView ? '1.5rem' : '0',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
        marginTop: isInitialView ? '1rem' : '0',
    },
    fontWeight: 400,
    color: theme.palette.mode === 'dark' ? '#b0bec5' : '#546e7a',
    lineHeight: 1.6,
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 16px',
    textAlign: 'center',
    fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
}));

const ChatContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isInitialView'
})(({ theme, isInitialView }) => ({
    height: isInitialView ? '30vh' : '80vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'all 0.5s ease-in-out',
    margin: isInitialView ? 'auto 0' : '0',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1),
        width: '100%',
        height: isInitialView ? '60vh' : '80vh',
    },
}));

const MessagesArea = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'dark' ? '#bdbdbd' : '#bdbdbd',
        borderRadius: '4px',
    },
}));

const Message = styled(Box)(({ theme, isBot }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'flex-start',
    alignSelf: isBot ? 'flex-start' : 'flex-end',
    maxWidth: '70%',
}));

const MessageBubble = styled(Box)(({ theme, isBot }) => ({
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        fontSize: '0.9rem',
    },
    backgroundColor: isBot
        ? theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff'
        : theme.palette.primary.main,
    color: isBot
        ? theme.palette.mode === 'dark' ? '#fff' : '#000'
        : '#fff',
    borderRadius: theme.spacing(2),
    boxShadow: isBot
        ? theme.palette.mode === 'dark'
            ? '0 2px 4px rgba(255,255,255,0.05)'
            : '0 2px 4px rgba(0,0,0,0.05)'
        : 'none',
}));

const OptionsContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f8fa',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
    borderRadius: '20px',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    boxShadow: theme.palette.mode === 'dark'
        ? '0 2px 20px rgba(255,255,255,0.05)'
        : '0 2px 20px rgba(0,0,0,0.05)',
    width: 'auto',
    '& .MuiButton-contained': {
        borderRadius: '15px',
        backgroundColor: '#2196F3',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1976d2',
            transform: 'translateY(-2px)',
            boxShadow: '0 5px 15px rgba(33,150,243,0.3)',
        },
        '&:active': {
            transform: 'translateY(0)',
        }
    },
    '& .MuiButton-outlined': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff',
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)',
        borderRadius: '15px',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '5px',
            height: '5px',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(33,150,243,0.2)',
            borderRadius: '50%',
            transform: 'scale(1)',
            opacity: 0,
            transition: 'all 0.5s ease-out',
        },
        '@keyframes pulse': {
            '0%': {
                transform: 'scale(1)',
                opacity: 0.5,
            },
            '100%': {
                transform: 'scale(20)',
                opacity: 0,
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? '#3d3d3d' : '#f0f7ff',
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : '#2196F3',
            transform: 'translateY(-2px)',
            boxShadow: theme.palette.mode === 'dark'
                ? '0 5px 15px rgba(255,255,255,0.1)'
                : '0 5px 15px rgba(33,150,243,0.2)',
            '&::after': {
                animation: 'pulse 1s ease-out infinite',
            }
        },
        '&:active': {
            transform: 'translateY(0)',
        }
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(1.5, 3),
    borderRadius: '15px',
    fontSize: '1rem',
    fontWeight: 500,
    transition: 'all 0.3s ease',
    background: theme.palette.mode === 'dark'
        ? 'linear-gradient(45deg, #90caf9 30%, #64b5f6 90%)'
        : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: theme.palette.mode === 'dark' ? '#000' : '#fff',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(33, 150, 243, 0.2)',
    }
}));

const options = {
    initial: [
        { id: 1, text: "What is Get to Know .me?", icon: "❓", next: "what" },
        { id: 2, text: "How does it work?", icon: "⚙️", next: "how" },
        { id: 3, text: "Why should I use it?", icon: "🎯", next: "why" }
    ],
    what: [
        { id: 4, text: "Tell me about the chat feature", icon: "💬", next: "chat" },
        { id: 5, text: "Can I see an example?", icon: "👀", next: "example" }
    ],
    how: [
        { id: 6, text: "How do I create my portfolio?", icon: "✍️", next: "create" },
        { id: 7, text: "How do visitors interact?", icon: "🤝", next: "interact" }
    ],
    why: [
        { id: 8, text: "How do I create my portfolio?", icon: "✍️", next: "create" },
        { id: 9, text: "Can you show an example?", icon: "👀", next: "example" }
    ],
    chat: [
        { id: 10, text: "How do I create my portfolio?", icon: "✍️", next: "create" }
    ],
    example: [
        { id: 12, text: "How do I create my portfolio?", icon: "✍️", next: "create" }
    ],
    create: [],
    interact: []
};

const responses = {
    what: "Get to Know .me is a revolutionary portfolio platform that turns your resume into an interactive chat experience. Instead of static pages, visitors can have natural conversations to learn about you! 🚀",
    how: "It's simple! Create your profile, add your experiences and skills, and we'll turn it into an engaging chat interface. Visitors can ask questions and get instant responses about your background! 💬",
    why: "Traditional portfolios are static and one-size-fits-all. With Get to Know .me, each visitor can have a personalized experience, asking exactly what they want to know about you! Plus, it's memorable and fun! ✨",
    chat: "Our AI-powered chat interface allows visitors to ask questions about your experience, skills, and background. It's like having a 24/7 interview where you can showcase your best self! 🤖",
    example: "Imagine a recruiter visiting your profile. Instead of scanning a PDF, they can ask 'What was your role at Company X?' or 'Tell me about your leadership experience.' and get immediate, personalized responses! 🎯",
    create: "Getting started is easy! Sign up, fill out your profile with your experiences and skills, and we'll automatically create your interactive chat portfolio. You can customize responses and add your personal touch! 🎨",
    interact: "Visitors simply type questions or click suggested topics to learn about you. Our AI understands natural language and provides relevant information from your profile. It's like having a conversation with you! 💡"
};

const getPublicPath = (path) => {
    return (process.env.PUBLIC_URL || '') + path;
};

const HomePage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isInitialView, setIsInitialView] = useState(true);
    const messagesEndRef = useRef(null);
    const [currentOptions, setCurrentOptions] = useState('initial');
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // Add initial message with delay
        const timer = setTimeout(() => {
            setMessages([
                { id: 1, text: "Hi! 👋 I'm the Get to Know .me assistant. Would you like to learn more about our innovative portfolio platform?", isBot: true }
            ]);
        }, 800); // 800ms delay

        return () => clearTimeout(timer);
    }, []);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleOptionClick = async (option) => {
        setIsInitialView(false);

        // Add user message
        const userMessage = { id: messages.length + 1, text: option.text, isBot: false };
        setMessages(prev => [...prev, userMessage]);

        // Simulate typing delay and add bot response
        setTimeout(() => {
            const botMessage = { id: messages.length + 2, text: responses[option.next], isBot: true };
            setMessages(prev => [...prev, botMessage]);

            // If there are no more options, show the sign-up button
            if (!options[option.next]?.length) {
                setCurrentOptions('final');
            } else {
                setCurrentOptions(option.next);
            }
        }, 500);
    };

    return (
        <PageContainer>
            <HeroSection isInitialView={isInitialView}>
                <MainTitle
                    component="h1"
                    gutterBottom
                    isInitialView={isInitialView}
                >
                    Get to Know<span style={{
                        color: theme.palette.mode === 'dark' ? '#90caf9' : '#2196F3',
                        fontWeight: 900,
                        textShadow: theme.palette.mode === 'dark' ? '3px 3px 6px rgba(255,255,255,0.2)' : '3px 3px 6px rgba(33,150,243,0.2)'
                    }}> .me</span>
                </MainTitle>
                <SubTitle
                    variant="h5"
                    component="h2"
                    isInitialView={isInitialView}
                >
                    Transform your portfolio into an <span style={{ color: theme.palette.mode === 'dark' ? '#64b5f6' : '#1976d2', fontWeight: 500 }}>interactive chat experience</span>.
                    Let visitors discover your story through <span style={{ color: theme.palette.mode === 'dark' ? '#64b5f6' : '#1976d2', fontWeight: 500 }}>natural conversation</span>.
                </SubTitle>
            </HeroSection>

            <Container maxWidth="md" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <ChatContainer isInitialView={isInitialView}>
                    <MessagesArea>
                        {messages.map((message) => (
                            <Message key={message.id} isBot={message.isBot}>
                                {message.isBot && (
                                    <Avatar
                                        src={getPublicPath("/man_ai.jpg")}
                                        alt="AI Assistant"
                                        sx={{
                                            width: { xs: 32, sm: 40 },
                                            height: { xs: 32, sm: 40 }
                                        }}
                                    />
                                )}
                                <MessageBubble isBot={message.isBot}>
                                    <Typography variant="body1">{message.text}</Typography>
                                </MessageBubble>
                                {!message.isBot && (
                                    <Avatar
                                        src={getPublicPath("/woman_ai.jpg")}
                                        alt="User"
                                        sx={{
                                            width: { xs: 32, sm: 40 },
                                            height: { xs: 32, sm: 40 }
                                        }}
                                    />
                                )}
                            </Message>
                        ))}
                        <div ref={messagesEndRef} style={{ height: 1 }} />
                    </MessagesArea>

                    <OptionsContainer>
                        {currentOptions === 'initial' && (
                            <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                                gap={1}
                                sx={{
                                    maxWidth: '900px',
                                    width: '100%',
                                    margin: '0 auto',
                                    position: 'relative',
                                    padding: '0 8px',
                                    justifyContent: 'center',
                                    '& .MuiButton-root:nth-of-type(1)': {
                                        animation: 'fadeInUp 0.5s ease-out',
                                        animationDelay: '0.1s',
                                    },
                                    '& .MuiButton-root:nth-of-type(2)': {
                                        animation: 'fadeInUp 0.5s ease-out',
                                        animationDelay: '0.2s',
                                    },
                                    '& .MuiButton-root:nth-of-type(3)': {
                                        animation: 'fadeInUp 0.5s ease-out',
                                        animationDelay: '0.3s',
                                    },
                                    '@keyframes fadeInUp': {
                                        from: {
                                            opacity: 0,
                                            transform: 'translateY(20px)',
                                        },
                                        to: {
                                            opacity: 1,
                                            transform: 'translateY(0)',
                                        },
                                    },
                                }}
                            >
                                {options[currentOptions].map((option) => (
                                    <Button
                                        key={option.id}
                                        variant={option.id === 1 ? "contained" : "outlined"}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <span style={{ marginRight: '8px', fontSize: '1.1em' }}>{option.icon}</span>
                                        {option.text}
                                    </Button>
                                ))}
                            </Stack>
                        )}
                        {(currentOptions !== 'initial' && currentOptions !== 'final') && (
                            <Stack
                                direction="row"
                                spacing={1}
                                flexWrap="wrap"
                                gap={1}
                                sx={{
                                    maxWidth: '900px',
                                    width: '100%',
                                    margin: '0 auto',
                                    position: 'relative',
                                    padding: '0 8px',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        top: '-10px',
                                        bottom: '-10px',
                                        background: theme.palette.mode === 'dark'
                                            ? 'linear-gradient(to bottom, rgba(30,30,30,0), rgba(30,30,30,1) 20%)'
                                            : 'linear-gradient(to bottom, rgba(245,248,250,0), rgba(245,248,250,1) 20%)',
                                        pointerEvents: 'none',
                                        zIndex: 0,
                                        borderRadius: '20px',
                                    }
                                }}
                            >
                                {options[currentOptions].map((option) => (
                                    <Button
                                        key={option.id}
                                        variant="outlined"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <span style={{ marginRight: '8px', fontSize: '1.1em' }}>{option.icon}</span>
                                        {option.text}
                                    </Button>
                                ))}
                            </Stack>
                        )}
                        {(currentOptions === 'final' || !options[currentOptions]?.length) && (
                            <Button
                                variant="contained"
                                onClick={() => navigate('/signup')}
                                sx={{ borderRadius: 15, ml: 'auto' }}
                            >
                                Create Your Chat Portfolio
                            </Button>
                        )}
                    </OptionsContainer>
                </ChatContainer>
            </Container>
        </PageContainer>
    );
};

export default HomePage;
