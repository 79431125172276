import React, { useState } from 'react';
import axios from '../api/myAxios';

function DataUrlUpload({ workspaceId }) {
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateUrl(url)) {
      setError('Please enter a valid URL');
      return;
    }

    setIsLoading(true);
    try {
      await axios.post('/api/data/url', {
        url,
        workspaceId,
        description
      });
      setUrl('');
      setDescription('');
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Error uploading URL');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-4">
      <h4>Upload URL</h4>
      <form onSubmit={handleSubmit} className="flex flex-col gap-2">
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter URL"
          className="p-2 border rounded"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter a description"
          className="p-2 border rounded"
          rows="3"
        />
        {error && <div className="text-red-500 text-sm">{error}</div>}
        <button
          type="submit"
          disabled={isLoading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
        >
          {isLoading ? 'Uploading...' : 'Upload URL'}
        </button>
      </form>
    </div>
  );
}

export default DataUrlUpload;
