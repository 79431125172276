import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import HomePage from './components/HomePage';
import PrivateRoute from './components/PrivateRoute';
import PersonalizedPage from './components/PersonalizedPage.js';
import UserProfile from './components/UserProfile';
import ThemeToggle from './components/ThemeToggle';
import { ThemeProvider } from './context/ThemeContext';
import { getSubdomain } from './utils/getSubdomain';
import React from 'react';

function App() {
  const subdomain = getSubdomain();

  const AppContent = () => (
    <>
      <ThemeToggle />
      <Routes>
        {subdomain ? (
          <Route path="/*" element={<PersonalizedPage subdomain={subdomain} />} />
        ) : (
          <>
            <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
            <Route path="/profile" element={<PrivateRoute component={UserProfile} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<HomePage />} />
          </>
        )}
      </Routes>
    </>
  );

  return (
    <ThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;