import React from 'react';
import { IconButton, useTheme } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from '../context/ThemeContext';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.1)' 
      : 'rgba(0,0,0,0.1)',
  },
}));

const ThemeToggle = () => {
  const theme = useTheme();
  const { darkMode, toggleDarkMode } = useThemeContext();

  return (
    <ToggleButton
      onClick={toggleDarkMode}
      color="inherit"
      aria-label="toggle dark mode"
    >
      {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
    </ToggleButton>
  );
};

export default ThemeToggle;
