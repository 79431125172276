import React, { useState } from "react";
import axios from "../api/myAxios";

const DataFileUpload = ({ workspaceId }) => {
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isFilePublic, setIsFilePublic] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError('');
        setSuccess(false);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file) {
            setError('Please select a file to upload.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            setError('File size is too large. Maximum size is 5MB.');
            return;
        }

        try {
            setUploading(true);
            setError('');

            const formData = new FormData();
            formData.append('file', file);
            formData.append('workspaceId', workspaceId);
            formData.append('isFilePublic', isFilePublic);
            formData.append('description', description);

            const response = await axios.post('/api/data/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('File uploaded successfully:', response.data);
            setSuccess(true);
            setFile(null);
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <form onSubmit={handleUpload} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Upload File
                    </label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className="mt-1 block w-full text-sm text-gray-500
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-md file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Enter a description for your file"
                        rows="3"
                    />
                </div>

                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="isPublic"
                        checked={isFilePublic}
                        onChange={(e) => setIsFilePublic(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="isPublic" className="ml-2 block text-sm text-gray-900">
                        Make file public
                    </label>
                </div>

                {error && (
                    <div className="text-red-600 text-sm">{error}</div>
                )}

                {success && (
                    <div className="text-green-600 text-sm">File uploaded successfully!</div>
                )}

                <button
                    type="submit"
                    disabled={uploading || !file}
                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                        ${uploading || !file
                            ? 'bg-blue-300 cursor-not-allowed'
                            : 'bg-blue-600 hover:bg-blue-700'}`}
                >
                    {uploading ? 'Uploading...' : 'Upload File'}
                </button>
            </form>
        </div>
    );
};

export default DataFileUpload;
