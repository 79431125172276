import React from 'react';
import ReactMarkdown from 'react-markdown';

function MessageItem({ message }) {
  const isUser = message.sender === 'user';

  return (
    <div className={`message-item ${isUser ? 'user-message' : 'ai-message'}`}>
      <div className="message-content">
        <ReactMarkdown>{message.text}</ReactMarkdown>
        <span className="message-timestamp">
          {new Date(message.timestamp).toLocaleTimeString()}
        </span>
      </div>
    </div>
  );
}

export default MessageItem;