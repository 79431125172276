import React, { useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import WorkspaceList from './WorkspaceList';
import DataFileUpload from './DataFileUpload';
import DataUrlUpload from './DataUrlUpload';
import DataTextUpload from './DataTextUpload';
import DataList from './DataList';
import axios from '../api/myAxios';
import ChatWindow from './ChatWindow';

function Dashboard() {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [data, setData] = useState(null);

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
  };

  useEffect(() => {
    axios
      .get('/api/dashboard')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  return (
    <div>
      <h2>User Dashboard</h2>
      <LogoutButton />
      <WorkspaceList onSelect={handleWorkspaceSelect} />
      {selectedWorkspace && (
        <>
          <h3>Workspace: {selectedWorkspace.name}</h3>
          <DataFileUpload workspaceId={selectedWorkspace._id} />
          <DataUrlUpload workspaceId={selectedWorkspace._id} />
          <DataTextUpload workspaceId={selectedWorkspace._id} />
          <DataList workspaceId={selectedWorkspace._id} />
          <ChatWindow subdomain={selectedWorkspace.subdomain} />
        </>
      )}
      {data ? <pre>{JSON.stringify(data, null, 2)}</pre> : 'Loading...'}
    </div>
  );
}

export default Dashboard;